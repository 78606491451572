<script>
// import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
// import store from "@/state/store";
// var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
  },
  data() {
    return {
      hr_attendance: [],
      Salaries: [],
      HrEmployees: [],
      hrDepartments: [],
      jobTitles: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrEmployees: null,
      HrEmployeesSearchMode: false,
      searchModel: "",
      cashAccounts: [],
      hr_weekdays: [],
      HrSalaryItems: [],
      data: {},
      shiftData: {},
      salaryItemData: {},
      emp_id: null,
      choices: [{ value: 1 ,label: "yes"},
                { value: 0 ,label: "no"}],
      // state: state,
    };
  },
  methods: {
    addHrEmployees() {
      // console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "hr_department_id",
            type: "select",
            options: (() => {
              return this.hrDepartments.map((data) => {
                return {
                  value: data.id,
                  label: data.name,
                };
              });
            })(),
            label: this.$t("hr_employees.department"),
          },
          {
            model: "cash_account_id",
            type: "select",
            options: (() => {
              return this.cashAccounts.map((data) => {
                return {
                  value: data.id,
                  label: this.$i18n.locale == "ar" ? data.name : data.name_en,
                };
              });
            })(),
            label: this.$t("hr_employees.cash_account"),
          },
          {
            model: "salaries_payable_subaccount_id",
            type: "select",
            options: (() => {
              return this.Salaries.map((data) => {
                return {
                  value: data.sas_id,
                  label:
                    this.$i18n.locale == "ar"
                      ? data.sas_name
                      : data.sas_name_en,
                };
              });
            })(),
            label: "hr_employees.salaries_account",
          },

          {
            model: "name",
            type: "text",

            label: this.$t("hr_employees.name"),
          },
          {
            model: "birthdate",
            type: "date",
            label: this.$t("hr_employees.birthdate"),
          },
          {
            model: "basic_salary",
            type: "number",
            label: this.$t("hr_employees.basic_salary"),
          },
          {
            model: "has_tax",
            type: "select",
            options: (() => {
              return [
                {
                  value: 1,
                  label: "yes",
                },
                {
                  value: 0,
                  label: "no",
                },
              ];
            })(),
            label: this.$t("hr_employees.has_tax"),
          },
          {
            model: "has_zakat",
            type: "select",
            options: (() => {
              return [
                {
                  value: 1,
                  label: "yes",
                },
                {
                  value: 0,
                  label: "no",
                },
              ];
            })(),
            label: this.$t("hr_employees.has_zakat"),
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("hr-employees", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    add(){
        return new Promise(resolve => {
        this.http.post("hr-employees", this.data).then((res) => {
          resolve(res.data.id)
          this.get(this.page);
        })
      })
    },
    async addHrEmployee(){
      this.emp_id = await this.add();
      this.shiftData.hr_employee_id = this.emp_id;
      this.salaryItemData.hr_emp_id = this.emp_id;

      this.http.post("hr-employees-working-hours", this.shiftData).then(() => {
      });

      this.http.post("hr-emp-salary-items", this.salaryItemData).then(() => {
      });

      document.querySelector(".btn-close-add").click();
    },
    editHrEmployees(app) {
      console.log('app',app);
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "hr_department_id",
            type: "select",
            options: (() => {
              return this.hrDepartments.map((data) => {
                return {
                  value: data.id,
                  label: data.name,
                };
              });
            })(),
            label: this.$t("hr_employees.department"),
          },
          {
            model: "cash_account_id",
            type: "select",
            options: (() => {
              return this.cashAccounts.map((data) => {
                return {
                  value: data.id,
                  label: this.$i18n.locale == "ar" ? data.name : data.name_en,
                };
              });
            })(),
            label: this.$t("hr_employees.cash_account"),
          },
          {
            model: "salaries_payable_subaccount_id",
            type: "select",
            options: (() => {
              return this.Salaries.map((data) => {
                return {
                  value: data.sas_id,
                  label:
                    this.$i18n.locale == "ar"
                      ? data.sas_name
                      : data.sas_name_en,
                };
              });
            })(),
            label: "hr_employees.salaries_account",
          },
          {
            model: "name",
            value: app?.name,
            type: "text",

            label: this.$t("hr_employees.name"),
          },
          {
            model: "birthdate",
            value: app?.birthdate,

            type: "date",
            label: this.$t("hr_employees.birthdate"),
          },
          {
            model: "basic_salary",
            type: "number",
            value: app?.basic_salary,

            label: this.$t("hr_employees.basic_salary"),
          },
          {
            model: "has_tax",
            type: "select",
            options: (() => {
              return [
                {
                  value: 1,
                  label: "yes",
                },
                {
                  value: 0,
                  label: "no",
                },
              ];
            })(),
            label: this.$t("hr_employees.has_tax"),
          },
          {
            model: "has_zakat",
            type: "select",
            options: (() => {
              return [
                {
                  value: 1,
                  label: "yes",
                },
                {
                  value: 0,
                  label: "no",
                },
              ];
            })(),
            label: this.$t("hr_employees.has_zakat"),
          },
          {
            model: "has_attendance",
            type: "select",
            options: (() => {
              return [
                {
                  value: 1,
                  label: "yes",
                },
                {
                  value: 0,
                  label: "no",
                },
              ];
            })(),
            label: this.$t("hr_employees.has_attendance"),
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("hr-employees", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.HrEmployeesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-employees/employees-search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrEmployees = res.data;
          console.log("######## files", this.HrEmployees);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrEmployeesSearchMode = false;
      this.get(this.page);
    },
    getHrWeekDays() {
      this.http.get("hr-weekdays").then((res) => {
        this.hr_weekdays = res.data;
      });
    },
    getHrSalaryItems() {
      this.http.get("hr-salary-items").then((res) => {
        this.HrSalaryItems = res.data;
      });
    },
    getHrEmployees() {
      this.http.get("hr-employees").then((res) => {
        this.HrEmployees = res.data;
      });
    },
    getCashAccounts() {
      this.http.get("cash-accounts/company-accounts").then((res) => {
        this.cashAccounts = res.data;
        console.log(this.cashAccounts);
      });
    },
    getHrDepatments() {
      this.http.get("hr-departments").then((res) => {
        this.hrDepartments = res.data;
      });
    },
    getJobTitles() {
      this.http.get("hr-departments").then((res) => {
        this.jobTitles = res.data;
      });
    },
    deleteHrEmployees(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("hr-employees", app.id).then(() => {

            this.get(this.page);
          });
        }
      });
    },
    getAccounts() {
      this.http
        .post("level-three-chart-of-accounts/ledger-sub-accounts", {
          ledger_name: "employees",
        })
        .then((res) => {
          if (res.status) {
            this.Salaries = res.data[0]?.sub_accounts;
          }
        });
    },

    get(page) {
      console.log(page);
      this.http
        .post("hr-employees/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployees = res.data;
          res.data.forEach((item) => {
            this.hr_attendance.push(item.hr_attendance);
          });
        });

    },
  },
  mounted(){
    // Stepper Start
    const progress = document.getElementById("progress");
    const prev = document.getElementById("prevBtn");
    const next = document.getElementById("nextBtn");
    const submit = document.getElementById("submit");
    const circles = document.querySelectorAll(".circle");
    const content = document.querySelectorAll(".stepper-content");

    let currentValue =1;

    next.addEventListener("click",function(){
      currentValue++;
      if(currentValue > circles.length) {
        currentValue = circles.length;
      }
      update()
    })

    prev.addEventListener("click",function(){
      currentValue--;
      if(currentValue < 1) {
        currentValue = 1;
      }
      update()
    })

    function update(){
      content.forEach((item,index)=>{
          if(index === currentValue - 1){
            item.style.display = 'block';
          } else {
            item.style.display = 'none';
          }
        });
      circles.forEach((circle,index)=> {
        if(index < currentValue) {
          circle.classList.add("active");
        } else {
          circle.classList.remove("active");
        }
      });
      const actives = document.querySelectorAll(".circle.active");
      progress.style.width = ((actives.length - 1) / (circles.length - 1)) * 100 +'%';

      if (currentValue === circles.length) {
        submit.style.display = 'block';
        next.style.display = 'none';
        next.disabled = true
      } else if (currentValue <= 1) {
        prev.disabled = true
      } else {
        submit.style.display = 'none';
        next.style.display = 'block';
        next.disabled = false
        prev.disabled = false
      }
    }
  },

  created() {
    this.get(1);
    this.getHrWeekDays();
    this.getHrSalaryItems();
    // this.getHrEmployees();
    this.getHrDepatments();
    this.getJobTitles();
    this.getAccounts();
    this.getCashAccounts();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="background-color: #2a3042 !important"
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <button
                  class="btn-close text-light"
                  v-if="HrEmployeesSearchMode"
                  @click="cancelappsearchMode()"
                ></button>
                <!-- <button
                  type="button"
                  @click="addHrEmployees()"
                  class="btn btn-light float-end mb-4"
                > -->
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#addModal"
                  class="btn btn-light float-end mb-4"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-centered table-striped table-nowrap"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th>#</th>
                    <th>{{ $t("hr_employees.name") }}</th>
                    <th>{{ $t("hr_employees.department") }}</th>
                    <th>{{ $t("hr_employees.birthdate") }}</th>
                    <th>{{ $t("hr_employees.cash_account") }}</th>
                    <th>{{ $t("hr_employees.basic_salary") }}</th>
                    <th>{{ $t("hr_employees.has_tax") }}</th>
                    <th>{{ $t("hr_employees.has_zakat") }}</th>
                    <th>{{ $t("hr_employees.created") }}</th>
                    <th>{{ $t("hr_employees.updated") }}</th>
                    <th>{{ $t("hr_employees.operations") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(app, index) in HrEmployees" :key="app" class="">
                    <td>{{ (index += 1) }}</td>
                    <td>{{ app?.name }}</td>
                    <td>{{ app?.hr_department?.name }}</td>
                    <td>{{ app?.birthdate }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? app?.cash_account?.name
                          : app?.cash_account?.name_en
                      }}
                    </td>
                    <td>{{ app?.basic_salary }}</td>
                    <td>{{ app?.has_tax ? "Yes" : "No" }}</td>
                    <td>{{ app?.has_zakat ? "Yes" : "No" }}</td>
                    <td>{{ app?.created?.split("T")[0] }}</td>
                    <td>{{ app?.updated?.split("T")[0] }}</td>

                    <td>
                      <router-link
                        class="btn btn-primary mx-1"
                        :to="`/hr_personal_records/empolyee_profile/${app.id}`"
                      >
                        {{ $t("popups.profile") }}
                      </router-link>
                      <a
                        class="btn btn-primary mx-1"
                        @click="editHrEmployees(app)"
                        href="javascript: void(0);"
                        role="button"
                        >{{ $t("popups.edit") }}</a
                      >

                      <a
                        class="btn btn-danger"
                        href="javascript: void(0);"
                        role="button"
                        @click="deleteHrEmployees(app)"
                        >{{ $t("popups.delete") }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end  table -->
            <!--Start add Modal-->
            <div
                class="modal fade"
                id="addModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                      <form @submit.prevent="addHrEmployee()">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            {{ $t("popups.add") }}
                          </h5>
                          <button
                            type="button"
                            class="btn-close btn-close-add"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style="margin: 0"
                          ></button>
                        </div>

                        <div class="modal-body">
                          <div class="row">
                            <div class="col-6"></div>
                            <div
                              class="col-6"
                              style="display: flex; justify-content: flex-end"
                            ></div>
                          </div>
                          <div>
                            <div class="progress-container">
                              <div class="progress" id="progress"></div>
                              <div class="circle active">1</div>
                              <div class="circle">2</div>
                              <div class="circle">3</div>
                            </div>
                          </div>
                          <div class="row stepper-content">
                            <div class="col-12 col-md-12">
                              <label>{{ $t("hr_employees.department") }}</label>
                              <select
                                v-model="data.hr_department_id"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.department')"
                              >
                                <option
                                  v-for="item in hrDepartments"
                                  :key="item"
                                  :value="item?.id"
                                >
                                  {{ item?.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <label>{{ $t("hr_employees.cash_account") }}</label>
                              <select
                                v-model="data.cash_account_id"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.cash_account')"
                              >
                                <option
                                  v-for="item in cashAccounts"
                                  :key="item"
                                  :value="item?.id"
                                >
                                  {{ this.$i18n.locale == "ar" ? item?.name : item?.name_en }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <label>{{ $t("hr_employees.salaries_account") }}</label>
                              <select
                                v-model="data.salaries_payable_subaccount_id"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.salaries_account')"
                              >
                                <option
                                  v-for="item in Salaries"
                                  :key="item"
                                  :value="item?.sas_id"
                                >
                                  {{ this.$i18n.locale == "ar"? item.sas_name : item.sas_name_en }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <div class="form-group">
                                <label>{{ $t("hr_employees.name") }}</label>
                                <input required v-model="data.name" type="text" class="form-control" />
                              </div>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <div class="form-group">
                                <label>{{ $t("hr_employees.birthdate") }}</label>
                                <input required v-model="data.birthdate" type="date" class="form-control" />
                              </div>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <div class="form-group">
                                <label>{{ $t("hr_employees.basic_salary") }}</label>
                                <input required v-model="data.basic_salary" type="number" class="form-control" />
                              </div>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <label>{{ $t("hr_employees.has_tax") }}</label>
                              <select
                                v-model="data.has_tax"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.has_tax')"
                              >
                                <option
                                  v-for="item in choices"
                                  :key="item"
                                  :value="item?.value"
                                >
                                  {{ item?.label }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <label>{{ $t("hr_employees.has_zakat") }}</label>
                              <select
                                v-model="data.has_zakat"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.has_zakat')"
                              >
                                <option
                                  v-for="item in choices"
                                  :key="item"
                                  :value="item?.value"
                                >
                                  {{ item?.label }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <label>{{ $t("hr_employees.has_attendance") }}</label>
                              <select
                                v-model="data.has_attendance"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.has_attendance')"
                              >
                                <option
                                  v-for="item in choices"
                                  :key="item"
                                  :value="item?.value"
                                >
                                  {{ item?.label }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row stepper-content" style="display: none;">
                            <div class="col-12 col-md-12 mt-2">
                              <label>{{ $t("hr_employees.employee_profile.weekday") }}</label>
                              <select
                                v-model="shiftData.hr_weekday_id"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.employee_profile.weekday')"
                              >
                                <option
                                  v-for="item in hr_weekdays"
                                  :key="item"
                                  :value="item?.id"
                                >
                                  {{ item?.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <div class="form-group">
                                <label>{{ $t("hr_employees.employee_profile.start_time") }}</label>
                                <input required v-model="shiftData.start_time" type="time" class="form-control" />
                              </div>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <div class="form-group">
                                <label>{{ $t("hr_employees.employee_profile.end_time") }}</label>
                                <input required v-model="shiftData.end_time" type="time" class="form-control" />
                              </div>
                            </div>
                          </div>
                          <div class="row stepper-content" style="display: none;">
                            <div class="col-12 col-md-12 mt-2">
                              <label>{{ $t("hr_employees.salary_item") }}</label>
                              <select
                                v-model="salaryItemData.hr_salary_item_id"
                                class="form-select mb-2"
                                :placeholder="$t('hr_employees.salary_item')"
                              >
                                <option
                                  v-for="item in HrSalaryItems"
                                  :key="item"
                                  :value="item?.id"
                                >
                                  {{ item?.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 mt-2">
                              <div class="form-group">
                                <label>{{ $t("hr_employees.value") }}</label>
                                <input required v-model="salaryItemData.amount" type="number" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="modal-footer"
                          :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                        >
                          <button type="button" class="stepper-btn" id="prevBtn" disabled>Prev</button>
                          <button type="button" class="stepper-btn" id="nextBtn">Next</button>

                          <button type="submit" class="btn btn-primary modal-btn" id="submit" style="display: none;">
                            {{ $t("popups.add") }}
                          </button>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn btn-danger modal-btn"
                          >
                            {{ $t("popups.cancel") }}
                          </button>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
            <!--End add Modal-->
            <!-- Button trigger modal -->

            <div>
              <!--   Apps  paginate     -->
              <ul
                class="pagination pagination-rounded justify-content-center mb-2"
              >
                <li class="page-item" :class="{ disabled: page == 1 }">
                  <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="get(page - 1)"
                    aria-label="Previous"
                  >
                    <i class="mdi mdi-chevron-left"></i>
                  </a>
                </li>
                <li
                  class="page-item"
                  :class="{ active: p == page }"
                  v-for="p in tot_pages"
                  :key="p"
                >
                  <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="get(p)"
                    >{{ p }}</a
                  >
                </li>

                <li class="page-item" :class="{ disabled: page == tot_pages }">
                  <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="get(page + 1)"
                    aria-label="Next"
                  >
                    <i class="mdi mdi-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  /* position: relative; */
  right: 317px;
  top: 6px;
  font-size: 15px;
}
.modal-content {
  z-index: -5;
}
.progress-container{
  display: flex;
  margin: 0 auto 30px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 350px;
  max-width: 100%;
}
.progress-container::before{
  content: "";
  width: 100%;
  background-color: #f1f1f1;
  height: 5px;
  position: absolute;
  z-index: -1;
}
.progress{
  width: 0;
  background-color: green;
  height: 5px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  z-index: -1;
  transition: 0.4s ease-in-out;
}
.circle{
  background: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #f1f1f1;
  border-radius: 50px;
}
.active{
  border-color: green;
  transition: 0.6s ease-in;
}
.stepper-btn{
  border: none;
  padding: 5px 8px;
  width: 80px;
  font-size: 14px;
  border-radius: 5px;
  background: green;
  color: #fff;
  cursor: pointer;
  line-height: 1.5rem;
}
.modal-btn{
  padding: 5px 8px;
  width: 80px;
  font-size: 14px;
  border-radius: 5px;
}
.stepper-btn:disabled{
  background-color: #f1f1f1;
  cursor: not-allowed;
  color: #000;
}
</style>
